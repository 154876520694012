import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef } from "react";
import ReactPlayer from "react-player";
import { ComponentProp, TrailerDisplayed } from "../../constants/types";
import {
  Container,
  Sections,
  Row,
  CardContainer,
  Card,
  Video,
  Poster,
  Details,
  Title,
  Rating,
  Genre,
  PosterContainer,
} from "./styles/show";

type VideoProp = {
  src: string;
  playerRef: React.RefObject<ReactPlayer>;
  isMuted: boolean;
  setIsMuted: (isMuted: boolean) => void;
  onUpdateTrailer: (
    trailerDisplayed: TrailerDisplayed | null | undefined
  ) => void;
  onTrailerReady: () => void;
  [x: string]: any;
};
function Show({ children, ...restProps }: ComponentProp) {
  return <CardContainer {...restProps}>{children}</CardContainer>;
}

Show.Sections = function ShowSections({
  children,
  ...restProps
}: ComponentProp) {
  return <Sections {...restProps}>{children}</Sections>;
};

Show.Container = function ShowContainer({
  children,
  ...restProps
}: ComponentProp) {
  return <Container {...restProps}>{children}</Container>;
};

Show.Row = function ShowRow({ children, ...restProps }: ComponentProp) {
  const newPosX = restProps.currentElementBounds[0];
  const newPosWidth = restProps.currentElementBounds[1];
  const numOfChildren = restProps.numberOfChildren;
  const rowRef = useRef<any>();
  const leftSpace = 220;

  useEffect(() => {
    if (rowRef && rowRef.current && newPosX) {
      // const rowRefPosX = rowRef.current.getBoundingClientRect().left;
      // const division =
      //   rowRef.current.getBoundingClientRect().width / numOfChildren;
      // console.log("rowRefPosX", rowRefPosX);
      // console.log("newPosX", newPosX);
      // console.log("newPosWidth", newPosWidth);
      // console.log("division", division);

      // Rimossi perché non si adattavano bene
      // TODO: trovare la formula corretta per calcolare lo scroll da item ad item
      // const newScrollPosition = newPosX + newPosWidth / 2 - rowRef.current.getBoundingClientRect().width / 2;
      // rowRef?.current.scrollTo({
      //   //left: newPosX === rowRefPosX ? 0 : newPosX + newPosWidth - 500,
      //   //left: newPosX,
      //   behavior: "smooth",
      // });
      rowRef.current.scrollLeft += newPosX - newPosWidth * .8
    }
  }, [newPosX, rowRef, newPosWidth, numOfChildren]);

  const renderSliderArrowButtons = () => {
    if (!rowRef || !rowRef.current) return;
    const containerBoxSizes = rowRef.current.getBoundingClientRect();
    const containerWidth = containerBoxSizes.width + leftSpace;
    if (containerWidth <= window.innerWidth) return;
    return (
      <div className="arrows-buttons-container">
        {rowRef.current.scrollLeft >= leftSpace && (
          <div
            onClick={() => {
              // console.log(
              //   "left offset: ",
              //   Math.max(rowRef.current.scrollLeft - window.innerWidth / 2, 0)
              // );
              rowRef?.current.scrollTo({
                left: Math.max(
                  rowRef.current.scrollLeft - window.innerWidth / 2,
                  0
                ),
                behavior: "smooth",
              });
            }}
            className="arrow-row-scroll-button left-scroll-button"
          >
            <div className="arrow-row-scroll-button__icon">
              <FontAwesomeIcon icon={faChevronLeft} />
            </div>
          </div>
        )}
        {rowRef.current.scrollLeft +
          (window.outerWidth - containerBoxSizes.left) <
          rowRef.current.scrollWidth && (
          <div
            onClick={() => {
              rowRef?.current.scrollTo({
                left: rowRef.current.scrollLeft + window.innerWidth / 2,
                behavior: "smooth",
              });
            }}
            className="arrow-row-scroll-button right-scroll-button"
          >
            <div className="arrow-row-scroll-button__icon">
              <FontAwesomeIcon icon={faChevronRight} />
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      {/** Da abilitare per visualizzare pulsanti di scroll laterale */}
      {/* {renderSliderArrowButtons()} */}
      <Row ref={rowRef} {...restProps}>
        {children}
      </Row>
    </>
  );
};

Show.Card = function ShowCard({ children, ...restProps }: ComponentProp) {
  return <Card {...restProps}>{children}</Card>;
};

Show.Video = function ShowVideo({
  src,
  playerRef,
  isMuted,
  setIsMuted,
  onUpdateTrailer,
  onTrailerReady,
  ...restProps
}: VideoProp) {
  return (
    <Video {...restProps}>
      {/* <ReactPlayer
        ref={playerRef}
        //url={`https://www.youtube.com/watch?v=${src}`}
        url={`${src}`}
        className="trailer-video"
        width="100%"
        height="100%"
        playing
        muted={isMuted}
        onReady={() => onTrailerReady()}
        onEnded={() => onUpdateTrailer(null)}
        config={PLAYER_CONFIG}
      /> */}
      {/** <Mute isMuted={isMuted} onMutedToggle={setIsMuted} /> */}
    </Video>
  );
};

Show.PosterContainer = function ShowPosterContainer({
  children,
  ...restProps
}: ComponentProp) {
  return <PosterContainer {...restProps}>{children}</PosterContainer>;
};

Show.Poster = function ShowPoster({ children, ...restProps }: ComponentProp) {
  return <Poster {...restProps}>{children}</Poster>;
};

Show.Details = function ShowDetails({ children, ...restProps }: ComponentProp) {
  return <Details {...restProps}>{children}</Details>;
};
Show.Title = function ShowTitle({ children, ...restProps }: ComponentProp) {
  return <Title {...restProps}>{children}</Title>;
};

Show.Rating = function ShowRating({ children, ...restProps }: ComponentProp) {
  return <Rating {...restProps}>{children}</Rating>;
};

Show.Genre = function ShowGenre({
  genreDetails,
  i,
  length,
  ...restProps
}: ComponentProp) {
  return (
    <Genre {...restProps}>
      <span>{`${genreDetails ? genreDetails.name : ""}`}</span>
      {i < length - 1 && i !== 2 && <span className="genre-dot">&bull;</span>}
    </Genre>
  );
};

export default Show;
