import styled from 'styled-components/macro';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
    z-index: 99;
    position: fixed;
    bottom: 0;
    left: 0;
    top: 0;
    // background: rgb(0,0,0);
    // background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
    color: white;
    width: 180px;
    overflow: hidden;
    opacity: 1;
    transition: opacity 200ms linear;
    @media (max-width: 1000px) {
        width: 160px;
    }

    &.hidden-drawer {
        opacity: 0;
    }
`;
export const DrawerLinkContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    font-weight: bold;
    position: relative;
    width: 100%;
    gap: .6rem;
    margin: 6rem 0;
    max-height: 600px;
    overflow-y: auto;
    overflow-x: hidden;
`;
export const DrawerLink = styled.div`
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    width: 100%;
    gap: .8rem;
    min-height: 2.86rem;
    border-radius: .32rem;
    padding-left: 1rem;
    overflow-x: hidden;
    letter-spacing: .5px;

    &.active,
    &:hover {
        background: rgb(2,108,93);
        background: linear-gradient(90deg, rgba(2,108,93,1) 0%, rgba(9,75,66,1) 50%, rgba(0,0,0,0) 80%);
    }

    .MuiSvgIcon-root {
        height: .8em !important;
        width: .8em !important;
        margin-bottom: 3px;
    }
`;