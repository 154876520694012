import React, { useEffect } from "react";
import App from "./App";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "./store/reducers";
import "./i18n.js";

const Setup: React.FC = () => {
  useEffect(() => {}, []);

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  );
};

export default Setup;
