import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import "./spinner.css";

export const Loader: React.FC = () => {
  return (
    <div className="spinner-container">
      <FontAwesomeIcon className="rotating" icon={faSpinner} color="#fff" />
    </div>
  );
};

export default Loader;
