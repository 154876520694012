import React, { useEffect, useState } from "react";
import { Show } from "../components";
import { SectionType } from "../api/movieEndpoints";
import {
  BACKDROP_PLACEHOLDER,
  POSTER_PLACEHOLDER,
} from "../constants/constants";
import { usePlayer } from "../context/PlayerContext";
import { ShowOverview } from "../constants/types";
import ReactPlayer from "react-player";
import { MicRounded, VideocamRounded } from "@material-ui/icons";
import TranslatedText from "../components/TranslatedText";

type ShowContainerType = {
  section: SectionType;
  show: ShowOverview;
  focused?: boolean;
  clicked?: boolean;
  saveAllChildrenOfRow: () => void;
};
function ShowContainer({
  section,
  show,
  focused = false,
  clicked = false, // passed by focusableComponent
  saveAllChildrenOfRow = () => {},
}: ShowContainerType) {
  const {
    category: { category },
    detailsTrailer: { setDetailsTrailer },
    trailerDisplayed: { trailerDisplayed, setTrailerDisplayed },
    heroTrailer: { setHeroTrailer },
    isMuted: { isMuted, setIsMuted },
    genres: { genres },
    detailsContent: { detailsContent, setDetailsContent },
  } = usePlayer();
  const showPoster =
    (trailerDisplayed &&
      ((trailerDisplayed.id === show.id.toString() &&
        trailerDisplayed.header !== section.title) ||
        trailerDisplayed.id !== show.id.toString())) ||
    !trailerDisplayed;
  const playerRef = React.createRef<ReactPlayer>();
  const [delayHandler, setDelayHandler] = useState<any>(null);
  const windowWidth = window.innerWidth;

  useEffect(() => {
    if (focused) {
      handleShowHover();
    } else {
      clearTimeout(delayHandler);
      setTrailerDisplayed(null);
    }
  }, [focused]);

  const handleShowHover = async () => {
    try {
      /** const endpoint =
        category === "series"
          ? SECTIONS.series.helpers.fetchTVVideos.replace(
              "{{tv_id}}",
              show.id.toString()
            )
          : SECTIONS.movies.helpers.fetchMovieVideos.replace(
              "{{movie_id}}",
              show.id.toString()
            ); 
      const response = await pepAxios.get();
      const trailerDetails: Video = response.data.results
        .reverse()
        .find(
          (video: Video) =>
            video.site === "YouTube" && video.type === "Trailer"
        );*/
      if (show.content_url) {
        console.log(show);
        setTrailerDisplayed({
          id: show.id.toString(),
          header: section.title,
          url: show.content_video_url ? show.content_url : show.backdrop_path,
          isLoaded: false,
        });
        setDetailsContent(show);
        setHeroTrailer();
        // setIsMuted(true);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onTrailerReady = () => {
    if (trailerDisplayed)
      setTrailerDisplayed({ ...trailerDisplayed, isLoaded: true });
  };

  const calculateIfNew = (dateString: string) => {
    const movieDate = new Date(dateString);
    const currentDate = new Date();
    const difference = currentDate.getTime() - movieDate.getTime();
    return Math.ceil(difference / (1000 * 3600 * 24)) <= 30;
  };

  const updateDetailTrailer = () => {
    setDetailsTrailer({
      id: show.id,
      key: trailerDisplayed ? trailerDisplayed.url : null,
      start:
        playerRef && playerRef.current ? playerRef.current.getCurrentTime() : 0,
    });
  };

  useEffect(() => {
    if (!!clicked) {
      updateDetailTrailer();
    }
  }, [clicked]);

  const truncate = (string: string, length: number) => {
    return string.length > length
      ? string.substr(0, length - 1) + "..."
      : string;
  };

  return (
    <Show>
      <Show.Card
        className={`${focused ? "card-is-focused" : ""}`}
        onMouseEnter={() => handleShowHover()}
        onFocus={() => handleShowHover()}
        onMouseLeave={() => {
          clearTimeout(delayHandler);
          setTrailerDisplayed(null);
        }}
        onClick={() => {
          console.log("click");
          setDetailsTrailer({
            id: show.id,
            key:
              show.content_video_url && trailerDisplayed
                ? trailerDisplayed.url
                : show.backdrop_path,
            start:
              playerRef && playerRef.current
                ? playerRef.current.getCurrentTime()
                : 0,
          });
          saveAllChildrenOfRow();
        }}
      >
        {!showPoster &&
          trailerDisplayed &&
          trailerDisplayed.url &&
          trailerDisplayed.url.indexOf(".mp3") === -1 && (
            <Show.Video
              src={trailerDisplayed.url}
              playerRef={playerRef}
              isMuted={isMuted}
              setIsMuted={setIsMuted}
              onUpdateTrailer={setTrailerDisplayed}
              onTrailerReady={onTrailerReady}
              className={trailerDisplayed.isLoaded ? "trailer-visible" : ""}
            />
          )}
        {(showPoster ||
          (trailerDisplayed &&
            trailerDisplayed.id === show.id.toString() &&
            !trailerDisplayed.isLoaded)) && (
          <Show.PosterContainer>
            <Show.Poster
              src={
                section.size === "lg"
                  ? show.poster_path
                    ? `${
                        // IMAGE_BASE_URL +
                        /** (windowWidth <= 600
                        ? IMAGE_SIZES.poster.small
                        : windowWidth <= 1000
                        ? IMAGE_SIZES.poster.medium
                        : IMAGE_SIZES.poster.large) + */
                        show.poster_path
                      }`
                    : POSTER_PLACEHOLDER
                  : show.backdrop_path
                  ? `${
                      // IMAGE_BASE_URL +
                      // IMAGE_SIZES.backdrop.small +
                      show.backdrop_path
                    }`
                  : BACKDROP_PLACEHOLDER
              }
              alt={show.name || show.title}
            />
          </Show.PosterContainer>
        )}

        <Show.Details>
          <Show.Title>{truncate(show.name || show.title || "", 60)}</Show.Title>
          <br />
          <Show.Rating>
            {calculateIfNew(show.release_date || show.first_air_date) && (
              <span className="new-badge"><TranslatedText translationKey="show.new" /></span>
            )}
            <span className="rating">
              <b style={{ marginRight: ".3rem" }}><TranslatedText translationKey="detail.categories" />{' '}</b>
              {detailsContent &&
                detailsContent.content_categories &&
                detailsContent.content_categories.map(
                  (category: any, i: number) =>
                    i === detailsContent.content_categories.length - 1
                      ? category
                      : category + ", "
                )}
            </span>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                opacity: ".8",
              }}
            >
              <b style={{ marginRight: ".3rem" }}><TranslatedText translationKey="show.typology" />{' '}</b>
              {detailsContent && detailsContent.content_audio_url ? (
                <MicRounded fontSize="small" />
              ) : null}
              {detailsContent && detailsContent.content_video_url ? (
                <VideocamRounded fontSize="small" />
              ) : null}
            </div>
          </Show.Rating>
          {section.size !== "lg" && (
            <React.Fragment>
              <br />
              <p>
                {genres && genres.length > 0
                  ? show.genre_ids.map((genreId, i) => {
                      if (i > 2) return null;
                      const genreDetails = genres.find(
                        (genre) => genre.id === genreId
                      );
                      return (
                        <Show.Genre
                          key={`${show.id}_${genreId}`}
                          genreDetails={genreDetails}
                          length={show.genre_ids.length}
                          i={i}
                        />
                      );
                    })
                  : null}
              </p>
            </React.Fragment>
          )}
        </Show.Details>
      </Show.Card>
    </Show>
  );
}

export default ShowContainer;
