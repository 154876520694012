import { ReduxAction } from "../../types/reducers";

export enum GRAPHIC_ACTIONS {
  SET_CURRENT_LANGUAGE = "graphicActions/setCurrentLanguage",
  SET_CURRENT_NEWSLETTER_MODAL = "graphicActions/setCurrentNewsletterModal",
}

export const setCurrentLanguage: ReduxAction<string> = (language: string) => {
  return {
    type: GRAPHIC_ACTIONS.SET_CURRENT_LANGUAGE,
    payload: language,
  };
};

export const setCurrentNewsletterModal: ReduxAction<boolean> = (showNewsletterModal: boolean) => {
  return {
    type: GRAPHIC_ACTIONS.SET_CURRENT_NEWSLETTER_MODAL,
    payload: showNewsletterModal,
  };
};
