import React, { useState, useEffect } from "react";
import Scrollbar from "react-scrollbars-custom";
import movieHttp from "../api/movie";
import { Loading } from "../components";
import {
  HeroContainer,
  SectionsContainer,
  ProfilesContainer,
  PlayerContainer,
  SearchContainer,
} from "../containers";
import { useUser } from "../context/UserContext";
import { PlayerContext } from "../context/PlayerContext";
import { DetailsContainer } from "../containers";
import { SECTIONS } from "../api/movieEndpoints";
import {
  DetailsTrailer,
  Genre,
  Profile,
  ShowOverview,
  TrailerDisplayed,
} from "../constants/types";
import Drawer from "../components/drawer";
import {
  FocusContext,
  useFocusable,
} from "@noriginmedia/norigin-spatial-navigation";
import LivestreamContainer from "../containers/livestream";
import NewsletterBanner from "../containers/newsletterBanner";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentNewsletterModal } from "../store/actions/graphic";
import { ReducerGraphic, Reducers } from "../types/reducers";

const Browse: React.FC = () => {
  const graphic: ReducerGraphic = useSelector(
    (store: Reducers) => store.graphic
  );
  const { userDetails } = useUser();
  // const [profile, setProfile] = useState<Profile>();
  const [profile, setProfile] = useState<Profile | undefined>({
    _id: "-1",
    name: "testProfile",
    avatar: "/images/branding/Logo_nh_tv.png",
  });
  const [loading, setLoading] = useState(true);
  const [playing, setPlaying] = useState<ShowOverview | null | undefined>();
  const [detailsTrailer, setDetailsTrailer] = useState<
    DetailsTrailer | null | undefined
  >();
  const [heroTrailer, setHeroTrailer] = useState<string | null | undefined>();
  const [trailerDisplayed, setTrailerDisplayed] = useState<
    TrailerDisplayed | null | undefined
  >();
  const [isMuted, setIsMuted] = useState(true);
  const [category, setCategory] = useState("series");
  const [isHeaderShown, setHeaderShown] = useState(false);
  const [sectionDisplayed, setSectionDisplayed] = useState(5);
  const [searchIsActive, setSearchIsActive] = useState(false);
  const [livestreamIsActive, setLivestreamIsActive] = useState(false);
  const [newsletterBannerIsVisible, setNewsletterBannerIsVisible] =
    useState(false);
  const [activeCatalog, setActiveCatalog] = useState(-1);
  const [genres, setGenres] = useState<Array<Genre> | undefined | null>();
  const [detailsContent, setDetailsContent] = useState<any>();
  const { ref, focusKey } = useFocusable({ isFocusBoundary: true });
  const dispatch = useDispatch();

  // /**
  //  * Checks if the browser supports the use of localStorage. If it doesn't, an error is thrown.
  //  */
  // const testLocalStorageSupport = async (): Promise<void> => {
  //   try {
  //     const data = localStorage.getItem("showNewsletter");
  //     if (data === undefined) {
  //       throw new Error("Naturhouse TV: Can't use localStorage");
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  useEffect(() => {
    // void testLocalStorageSupport();
    setNewsletterBannerIsVisible(graphic.showNewsletterModal);
    setLoading(true);
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 1600);
    return () => {
      if (!timeout) return;
      clearTimeout(timeout);
    };
  }, []);

  useEffect(() => {
    try {
      const endpoint =
        category === "series"
          ? SECTIONS.series.helpers.fetchTVGenres
          : SECTIONS.movies.helpers.fetchMovieGenres;
      movieHttp
        .get(endpoint)
        .then((response) => setGenres(() => response.data.genres));
      setSectionDisplayed(5);
    } catch (err) {
      console.error(err);
    }
  }, [category, setSectionDisplayed]);

  const handleOnScroll = ({ clientHeight, scrollTop, scrollHeight }: any) => {
    const bottom = Math.ceil(clientHeight + scrollTop) >= scrollHeight - 300;
    if (bottom) {
      setSectionDisplayed((sectionDisplayed) => {
        const newDisplayed = sectionDisplayed + 5;
        return newDisplayed <= SECTIONS[category].sections.length
          ? newDisplayed
          : sectionDisplayed;
      });
    }
    if (scrollTop > 100 && !isHeaderShown) {
      setHeaderShown(true);
    } else if (scrollTop <= 100 && isHeaderShown) {
      setHeaderShown(false);
    }
  };

  return profile ? (
    <PlayerContext.Provider
      value={{
        playing: { playing, setPlaying },
        detailsTrailer: { detailsTrailer, setDetailsTrailer },
        isMuted: { isMuted, setIsMuted },
        category: { category, setCategory },
        heroTrailer: { heroTrailer, setHeroTrailer },
        trailerDisplayed: { trailerDisplayed, setTrailerDisplayed },
        genres: { genres, setGenres },
        detailsContent: { detailsContent, setDetailsContent },
      }}
    >
      <Scrollbar
        tabIndex={-1}
        noDefaultStyles
        className="main-scrollbar"
        onScroll={(e: any) => handleOnScroll(e)}
      >
        <FocusContext.Provider value={focusKey}>
          <div className="browse" ref={ref}>
            {loading && profile ? (
              <Loading src={`${profile.avatar}`} />
            ) : (
              <Loading.ReleaseBody />
            )}
            {!!detailsTrailer && !(playing as any)?.content_video_url && (
              <DetailsContainer />
            )}
            {newsletterBannerIsVisible && (
              <NewsletterBanner
                close={() => {
                  // localStorage.setItem('showNewsletter', 'false')
                  dispatch(setCurrentNewsletterModal(false));
                  setNewsletterBannerIsVisible(false);
                }}
              />
            )}
            {/* {!playing ||
            (playing.id === detailsContent.id &&
              detailsContent?.content_audio_url) ? ( */}
            <Drawer
              setFocusFlag={
                /**!searchIsActive &&*/ ((playing === undefined &&
                  detailsTrailer === undefined) ||
                  (playing &&
                    playing.id === detailsContent.id &&
                    detailsContent?.content_audio_url)) &&
                !newsletterBannerIsVisible
              }
              profile={profile}
              setActiveCatalog={setActiveCatalog}
              activeCatalogId={activeCatalog}
              setCategory={setCategory}
              activeCategory={category}
              setSearchIsActive={setSearchIsActive}
              setLivestreamIsActive={setLivestreamIsActive}
              clearCurrentUser={() => setProfile(undefined)}
            />
            {/* ) : null} */}
            {searchIsActive ? (
              <SearchContainer activeCategory={category} />
            ) : livestreamIsActive ? (
              <LivestreamContainer />
            ) : (
              <>
                <HeroContainer
                  profile={profile}
                  activeCatalog={activeCatalog}
                />
                {/* <LanguageSelector /> */}
                {activeCatalog !== -1 && (
                  <SectionsContainer
                    catalog={activeCatalog}
                    category={category}
                    sectionDisplayed={sectionDisplayed}
                  />
                )}
              </>
            )}
            {!!playing &&
              /**playing.id === detailsContent.id &&*/
              detailsContent?.content_video_url &&
              !playing?.content_audio_url && (
                <PlayerContainer
                  playing={playing}
                  setPlaying={(playingMeta) => {
                    setPlaying(playingMeta);
                    if (!playingMeta) {
                      setTrailerDisplayed(undefined);
                      setDetailsTrailer(undefined);
                      sessionStorage.setItem("playingElementRow", "[]");
                    }
                  }}
                />
              )}
          </div>
        </FocusContext.Provider>
      </Scrollbar>
    </PlayerContext.Provider>
  ) : (
    <ProfilesContainer
      userDetails={userDetails}
      setProfile={(profile) => {
        setProfile(profile);
      }}
    />
  );
};

export default Browse;
