import React, { useEffect, useState } from "react";
import { Show } from "../components";
import SliderContainer from "./slider";
import pepAxios from "../api/contents";
import { GENERAL_SECTIONS } from "../api/contentsEndpoints";
import TranslatedText from "../components/TranslatedText";

type SectionsContainerType = {
  category: string;
  catalog: number;
  sectionDisplayed: number;
};
const SectionsContainer: React.FC<SectionsContainerType> = (props) => {
  const [categories, setCategories] = useState([]);

  function fetchCatalogCategories(activeCatalog: number) {
    if (activeCatalog < 0) return;
    pepAxios
      .get(
        GENERAL_SECTIONS.helpers.fetchCatalogCategories.replace(
          "{{catalog_id}}",
          activeCatalog.toString()
        )
      )
      .then((response) => {
        setCategories(response.data.items);
      });
  }

  useEffect(() => {
    fetchCatalogCategories(props.catalog);
  }, [props.catalog]);

  return (
    <React.Fragment>
      {props.catalog !== -1 && (
        <Show.Sections>
          {categories.length > 0 ? (
            categories.map((catalogCategory: any) => {
              return (
                <SliderContainer
                  key={catalogCategory.id}
                  section={null}
                  category={catalogCategory}
                  catalog={props.catalog}
                />
              );
            })
          ) : (
            <Show.Container>
              <h2><TranslatedText translationKey="system.emptyCatalog" /></h2>
            </Show.Container>
          )}
        </Show.Sections>
      )}
    </React.Fragment>
  );
};

export default SectionsContainer;
