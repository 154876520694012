import React from "react";
import ReactDOM from "react-dom";
import "normalize.css";
import { GlobalStyles } from "./global-styles";
import { init } from "@noriginmedia/norigin-spatial-navigation";
import Setup from "./Setup";

init({
  // debug: true,
  // visualDebug: true,
});

ReactDOM.render(
  <React.StrictMode>
    <GlobalStyles />
    <Setup />
  </React.StrictMode>,
  document.getElementById("root")
);
