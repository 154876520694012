import styled from 'styled-components/macro';
import { Link as RouterLink } from 'react-router-dom';
import { Spinner as LoadingSpinner } from '../../loading/styles/loading';

export const Container = styled.div`
	display: flex;
	height: 100vh;
	background: linear-gradient(to top, rgb(0 0 0 / 90%) 0, rgb(0 0 0 / 20%) 60%, rgb(0 0 0 / 90%) 100%),
		url('/images/branding/nh_auth_pages_bg.png');
	background-size: cover;
	background-position: 50% 50%;
`;
export const Inner = styled.div`
	background: rgba(0, 0, 0, .75);
	max-width: 20rem;
	margin: auto;
	padding: 3.5rem 4.5rem 5.5rem;
	color: #fff;
	border-radius: 0.25rem;
`;
export const Title = styled.h1`
	font-size: 2rem;
	margin: 0 0 1.5rem;
`;
export const FormGroup = styled.form`
	display: flex;
	flex-flow: row wrap;
`;
export const Input = styled.input`
	width: 100%;
	background: rgba(255, 255, 255, .1);
	color: #fff;
	padding: 1rem;
	margin: 0.5rem 0;
	border-radius: 0.25rem;
	outline: none;
	border: none;
	border: 2px solid transparent;
	transition: border-color 0.18s ease-out;
	&:focus {
		border-color: #fff;
	}
	&.has-error {
		border: 2px solid #e87c03;
	}
	@media (max-width: 1000px) {
		padding: 0.5rem 1em;
	}
`;
export const Button = styled.button`
	width: 100%;
	background-color: #055e51;
	color: #fff;
	font-weight: 800;
	font-size: 1rem;
	margin-top: 1.5rem;
	padding: 1rem 0.25rem;
	border: none;
	cursor: pointer;
	border-radius: 0.25rem;
	transition: all 0.1s ease-in;

	&:hover {
		background-color: #044037;
	}

	&:disabled {
		color: #ccc;
		cursor: not-allowed;
	}
`;
export const Spinner = styled(LoadingSpinner)`
	background: none;
	width: 2rem;
	height: 0;
	margin-top: -0.15rem;
	:after {
		width: 1.5rem;
		height: 1.5rem;
    filter: contrast(0) brightness(1.75);
		margin-top: 0;
		margin-left: 0;
	}
`;
export const Text = styled.p`
	color: #737373;
	font-size: 1.1rem;
`;
export const TextSmall = styled.p`
	font-size: 0.8rem;
	color: #8c8c8c;

	a {
		color: #8c8c8c;
		font-weight: bold;
	}
`;
export const Link = styled(RouterLink)`
	color: #fff;
	text-decoration: none;
	cursor: pointer;

	&:hover {
		text-decoration: underline;
	}
`;
export const Error = styled.p`
	margin: 0 0.25rem 0.5rem;
	font-size: 1em;
	font-weight: 500;
	color: #F02D3A;

	&.boxed {
		background-color: #F02D3A;
		color: #fff;
		border-radius: 0.25rem;
		padding: 0.5rem;
	}
`;
