import React, { useState, useRef, useEffect } from "react";
import Scrollbar from "react-scrollbars-custom";
import { Details, Form } from "../components";
import { LockBody } from "../components/loading/styles/loading";
import {
  FocusContext,
  useFocusable,
} from "@noriginmedia/norigin-spatial-navigation";
import FocusableComponent from "./focusableComponent";
import TranslatedText from "../components/TranslatedText";
import NewsletterBannerContainer from "../components/newsletterBanner";
import axios from "axios";
import { Checkbox } from "@material-ui/core";

interface Props {
  close: () => void;
}

/** export const privacyPolicyUrl =
  "https://22hbgtest.s3.eu-west-2.amazonaws.com/naturhouse/Naturhousetv-Informativa+privacy+TV-v1.pdf"; */

export const privacyPolicyUrl =
  "https://www.naturhouse.it/privacy-policy-naturhouse-tv";

const NewsletterBanner: React.FC<Props> = (props) => {
  const [email, setEmail] = useState("");
  const [scrollIndex] = useState<number | undefined>(0);
  const [privacyFlag, setPrivacyFlag] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);
  const subscribeButton = useRef<HTMLDivElement>(null);
  const closeButton = useRef<HTMLDivElement>(null);
  const [subscriptionRequestStatus, setSubscriptionRequestStatus] =
    useState("");
  const { ref, focusKey, focusSelf } = useFocusable({ isFocusBoundary: true });
  const [isTouched, setIsTouched] = useState({
    email: false,
  });
  const emailInvalid =
    isTouched.email && (email.length < 4 || email.length > 60);

  const subscribeUserToNewsletter = async () => {
    try {
      if (!email) return;
      const naturhouseServicesBaseUrl =
        "https://solutions.api-services.cloud/naturhouse-api/register-mail";
      const response = await axios.post(naturhouseServicesBaseUrl, { email });
      console.log(response.data);
      setSubscriptionRequestStatus("SUCCESS");
    } catch (error) {
      console.error(error);
      setSubscriptionRequestStatus("ERROR");
    }
  };

  useEffect(() => {
    if (!subscriptionRequestStatus) return;
    if (subscriptionRequestStatus === "SUCCESS") {
      setTimeout(() => {
        props.close();
      }, 2500);
    }
  }, [subscriptionRequestStatus, props]);

  useEffect(() => {
    setTimeout(() => {
      focusSelf();
    }, 500);
  }, [focusSelf]);

  return (
    <FocusContext.Provider value={focusKey}>
      <NewsletterBannerContainer>
        <LockBody />
        {(subscriptionRequestStatus === "SUCCESS" ||
          subscriptionRequestStatus === "ERROR") && (
          <div
            style={{
              background:
                subscriptionRequestStatus === "SUCCESS"
                  ? "#1B9D51"
                  : subscriptionRequestStatus === "ERROR"
                  ? "#AF1D1D"
                  : "",
            }}
            className="newsletter-alert-box"
          >
            {subscriptionRequestStatus === "SUCCESS" ? (
              <TranslatedText translationKey="newsletterPanel.successMessage" />
            ) : subscriptionRequestStatus === "ERROR" ? (
              <TranslatedText translationKey="newsletterPanel.errorMessage" />
            ) : null}
          </div>
        )}
        <NewsletterBannerContainer.Inner childRef={modalRef} ref={ref}>
          <Scrollbar
            noDefaultStyles
            scrollTop={scrollIndex}
            className="main-scrollbar modal-scrollbar"
          >
            <NewsletterBannerContainer.Overlay />
            <NewsletterBannerContainer.HeaderImage />
            <NewsletterBannerContainer.HeaderText>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: 16,
                }}
              >
                <NewsletterBannerContainer.Title>
                  <TranslatedText translationKey="newsletterPanel.title" />
                </NewsletterBannerContainer.Title>
                <FocusableComponent
                  scrollPageIsActive
                  enterPressed={() => props.close()}
                  focusChanged={() => {
                    document.getElementById("newsletterInput")?.blur();
                  }}
                >
                  <Details.Close onClick={() => props.close()} />
                </FocusableComponent>
              </div>
              <NewsletterBannerContainer.Subtitle>
                <TranslatedText translationKey="newsletterPanel.subtitle" />
              </NewsletterBannerContainer.Subtitle>
            </NewsletterBannerContainer.HeaderText>
            <div className="newsletter-banner-body">
              <FocusableComponent
                scrollPageIsActive
                enterPressed={() => {
                  document.getElementById("newsletterInput")?.focus();
                }}
              >
                <Form.Input
                  style={{ width: "calc(100% - 33px)", margin: 0 }}
                  id={"newsletterInput"}
                  placeholder="Email"
                  value={email}
                  onChange={({ target }: any) => {
                    if (!isTouched.email)
                      setIsTouched((prevIsTouched) => {
                        return { ...prevIsTouched, email: true };
                      });
                    setEmail(target.value);
                  }}
                  className={emailInvalid ? "has-error" : ""}
                />
              </FocusableComponent>
              {emailInvalid && (
                <p
                  style={{
                    margin: "0 0.2rem 0.5rem 0",
                    fontSize: ".86em",
                    fontWeight: "bold",
                    color: "#F02D3A",
                    letterSpacing: ".4px",
                  }}
                >
                  <TranslatedText translationKey="system.wrongEmailFormatError" />
                </p>
              )}
              <div style={{ paddingLeft: 6 }}>
                <FocusableComponent
                  enterPressed={() => {
                    setPrivacyFlag(!privacyFlag);
                  }}
                  focusChanged={() => {
                    document.getElementById("newsletterInput")?.blur();
                  }}
                >
                  <Checkbox
                    color="primary"
                    checked={privacyFlag}
                    onChange={(e) => setPrivacyFlag(e.target.checked)}
                  />
                </FocusableComponent>
                <span style={{ top: 9, position: "relative" }}>
                  Acconsento al trattamento dei dati personali per ricevere la
                  newsletter di Naturhouse e confermo di aver preso visione
                  dell'Informativa privacy di Naturhouse.
                </span>
              </div>
              <div className="newsletter-banner-buttons-container">
                <div>
                  <FocusableComponent
                    scrollPageIsActive
                    enterPressed={() => {
                      window.open(privacyPolicyUrl, "_blank");
                    }}
                    focusChanged={() => {
                      document.getElementById("newsletterInput")?.blur();
                    }}
                  >
                    <a
                      style={{ float: "left" }}
                      href={privacyPolicyUrl}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Privacy policy
                    </a>
                  </FocusableComponent>
                </div>
                <div>
                  <FocusableComponent
                    scrollPageIsActive
                    enterPressed={() => {
                      props.close();
                    }}
                    focusChanged={() => {
                      document.getElementById("newsletterInput")?.blur();
                    }}
                  >
                    <Details.Button
                      ref={closeButton}
                      onClick={() => {
                        props.close();
                      }}
                    >
                      <span>
                        <TranslatedText translationKey="detail.notInterested" />
                      </span>
                    </Details.Button>
                  </FocusableComponent>
                  {emailInvalid || email.length === 0 || !privacyFlag ? (
                    <Details.Button
                      ref={subscribeButton}
                      style={{
                        opacity: ".5",
                        margin: "3px",
                      }}
                      className="white-btn"
                      onClick={() => null}
                    >
                      <span>
                        <TranslatedText translationKey="detail.subscribeMe" />
                      </span>
                    </Details.Button>
                  ) : (
                    <FocusableComponent
                      scrollPageIsActive
                      enterPressed={() => {
                        if (emailInvalid || email.length === 0) return;
                        subscribeUserToNewsletter();
                      }}
                      focusChanged={() => {
                        document.getElementById("newsletterInput")?.blur();
                      }}
                    >
                      <Details.Button
                        ref={subscribeButton}
                        style={{
                          opacity:
                            emailInvalid || email.length === 0 ? ".5" : "1",
                        }}
                        className="white-btn"
                        onClick={() => {
                          if (emailInvalid || email.length === 0) return;
                          subscribeUserToNewsletter();
                        }}
                      >
                        <span>
                          <TranslatedText translationKey="detail.subscribeMe" />
                        </span>
                      </Details.Button>
                    </FocusableComponent>
                  )}
                </div>
              </div>
            </div>
          </Scrollbar>
        </NewsletterBannerContainer.Inner>
      </NewsletterBannerContainer>
    </FocusContext.Provider>
  );
};

export default NewsletterBanner;
