import React, { useEffect, useMemo, useState } from "react";
import { Form, Search } from "../components";
import { ShowContainer } from ".";
import { ShowOverview } from "../constants/types";
import { SearchHeader } from "../components/search/styles/search";
import { SECTIONS } from "../api/movieEndpoints";
import movieHttp from "../api/movie";
import pepAxios from "../api/contents";
import { GENERAL_SECTIONS } from "../api/contentsEndpoints";
import FocusableComponent from "./focusableComponent";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ReducerGraphic, Reducers } from "../types/reducers";

type SearchContainerType = {
  activeCategory: string;
};
const SearchContainer: React.FC<SearchContainerType> = ({
  activeCategory,
}: SearchContainerType) => {
  const graphic: ReducerGraphic = useSelector(
    (store: Reducers) => store.graphic
  );
  const [query, setQuery] = useState("");
  const [searchResults, setSearchResults] = useState<ShowOverview[]>([]);
  const [catalogsIds, setCatalogsIds] = useState<number[]>([]);
  const { t } = useTranslation();

  const handleSearch = () => {
    if (query) {
      const endpoint =
        activeCategory === "series"
          ? SECTIONS.series.helpers.searchTV.replace("{{query}}", query)
          : SECTIONS.movies.helpers.searchMovie.replace("{{query}}", query);
      movieHttp
        .get(endpoint)
        .then((response: any) => {
          response.data.results.sort((a: ShowOverview, b: ShowOverview) =>
            a.popularity > b.popularity
              ? -1
              : b.popularity > a.popularity
              ? 1
              : 0
          );
          setSearchResults(response.data.results || []);
        })
        .catch((e: any) => console.log(e));
    } else {
      setSearchResults([]);
    }
  };

  const parseOrgParameter = useMemo(() => {
    const urlString = new URLSearchParams(window.location.search);
    return urlString.get("idOrg") || "55";
  }, []);

  const fetchCatalogsLabels = useMemo(() => {
    pepAxios
      .get(
        GENERAL_SECTIONS.helpers.fetchCatalogs.replace(
          "{{org_id}}",
          parseOrgParameter
        )
      )
      .then((response) => {
        setCatalogsIds(
          response.data.items.map((catalog: any) => {
            return catalog.id;
          })
        );
      });
  }, []);

  useEffect(() => fetchCatalogsLabels, []);

  const handleContentSearch = () => {
    if (query) {
      catalogsIds.map((catalogId) => {
        pepAxios
          .get(
            GENERAL_SECTIONS.helpers.searchOrganizationContents
              .replace("{{org_id}}", "55")
              .replace("{{locale}}", graphic.currentLanguage || 'it')
              .replace("{{query_search}}", query)
          )
          .then((response) => {
            setSearchResults(
              response.data
                ? response.data
                    // .filter(
                    //   (content: any) =>
                    //     content.localized[graphic.currentLanguage] &&
                    //     (content.localized[graphic.currentLanguage].audio_url ||
                    //       content.localized[graphic.currentLanguage]
                    //         .audio_url ||
                    //       content.localized[graphic.currentLanguage].video_url)
                    // )
                    .map((content: any) => {
                      return {
                        id: content.id,
                        overview: content.localized[graphic.currentLanguage].description,
                        poster_path:
                          content.localized[graphic.currentLanguage].img_url,
                        backdrop_path:
                          content.localized[graphic.currentLanguage].img_url,
                        genre_ids: [content.id_content_type],
                        popularity: 0,
                        vote_average: 0,
                        vote_count: 0,
                        original_language: content.locales,
                        original_title:
                          content.localized[graphic.currentLanguage].title,
                        title: content.localized[graphic.currentLanguage].title,
                        name: content.localized[graphic.currentLanguage].title,
                        original_name:
                          content.localized[graphic.currentLanguage].title,
                        content_url: content.localized[graphic.currentLanguage]
                          .audio_url
                          ? content.localized[graphic.currentLanguage].audio_url
                          : content.localized[graphic.currentLanguage]
                              .video_url,
                        content_audio_url:
                          content.localized[graphic.currentLanguage]
                            .audio_url || "",
                        content_video_url:
                          content.localized[graphic.currentLanguage]
                            .video_url || "",
                        content_categories: content.categories ? content.categories.map(
                          (cat: any) => cat.value
                        ) : [],
                        release_date: content.created,
                        tags: content.tags || [],
                        catalog: catalogId,
                      };
                    })
                : []
            )
                  })
          .catch((e: any) => console.log(e));
      });
    } else {
      setSearchResults([]);
    }
  };

  useEffect(() => {
    handleContentSearch();
  }, [query, graphic.currentLanguage]);

  const saveChildInStorage = (singleContent: any) => {
    if (!singleContent.content_audio_url) return;
    sessionStorage.setItem(
      "playingElementRow",
      JSON.stringify([singleContent])
    );
  };

  return (
    <Search>
      <SearchHeader>
        <FocusableComponent
          scrollPageIsActive
          enterPressed={() => {
            document.getElementById("searchInput")?.focus();
          }}
        >
          <Form.Input
            id={"searchInput"}
            placeholder={t("navigation.search")}
            onChange={(e: {
              target: { value: React.SetStateAction<string> };
            }) => {
              let timer;
              clearTimeout(timer);
              timer = setTimeout(() => {
                setQuery(e.target.value);
              }, 800);
            }}
          />
        </FocusableComponent>
      </SearchHeader>
      {searchResults.length > 0 ? (
        <Search.Row className="md-container">
          {searchResults.map((result) => (
            <FocusableComponent
              focusChanged={() => {
                console.log("change focus");
                document.getElementById("searchInput")?.blur();
              }}
              key={result.id}
              scrollPageIsActive
            >
              <ShowContainer
                saveAllChildrenOfRow={() => saveChildInStorage(result)}
                section={{ title: "Search Result", endpoint: "" }}
                show={result}
              />
            </FocusableComponent>
          ))}
        </Search.Row>
      ) : query ? (
        <p>{t("search.emptyResultsSearch")}</p>
      ) : (
        <p>{t("search.searchPlaceholder")}</p>
      )}
    </Search>
  );
};

export default SearchContainer;
