import {
  FocusContext,
  useFocusable,
} from "@noriginmedia/norigin-spatial-navigation";
import React, { useEffect, useState } from "react";
import { Player } from "../components";
import DevelopmentConsole from "../components/developmentConsole";
import { LockBody } from "../components/loading/styles/loading";
import { ShowOverview } from "../constants/types";
import FocusableComponent from "./focusableComponent";
import TranslatedText from "../components/TranslatedText";

type PlayerContainerType = {
  playing: ShowOverview;
  setPlaying: (userDetails?: ShowOverview) => void;
};
function PlayerContainer({ playing, setPlaying }: PlayerContainerType) {
  const [isPaused, setIsPaused] = useState<boolean>();
  const [errorObj, setErrorObj] = useState<any>("arrore");
  const { ref, focusKey, focusSelf } = useFocusable();

  useEffect(() => {
    focusSelf();
  }, [focusSelf]);

  return (
    <FocusContext.Provider value={focusKey}>
      <Player ref={ref}>
        {isPaused && (
          <React.Fragment>
            <Player.Overlay />
            <Player.Details>
              <p>
                <TranslatedText translationKey="player.watching" />
              </p>
              <Player.Title>
                {playing.name || playing.title || playing.original_name}
              </Player.Title>
            </Player.Details>
            <p className="paused-text" style={{ zIndex: 2 }}>
              <TranslatedText translationKey="player.paused" />
            </p>
          </React.Fragment>
        )}
        {playing.content_audio_url && (
          <Player.Placeholder
            image={playing.poster_path || playing.backdrop_path}
          />
        )}
        <Player.Video
          contentUrl={playing.content_url}
          setIsPaused={setIsPaused}
          isPaused={isPaused}
          setError={setErrorObj}
          handleMediaEnd={() => setPlaying()}
        />
        {/* {errorObj && <DevelopmentConsole text={errorObj} />} */}
        <div
          style={{ position: "absolute", top: "1rem", left: "1rem", zIndex: 8 }}
        >
          <FocusableComponent enterPressed={() => setPlaying()}>
            <Player.Back onClick={() => setPlaying()} />
          </FocusableComponent>
        </div>
        <div
          style={{
            position: "absolute",
            top: "1rem",
            right: "1rem",
            zIndex: 8,
          }}
        >
          <FocusableComponent enterPressed={() => setIsPaused(!isPaused)}>
            <Player.Pause
              onClick={() => setIsPaused(!isPaused)}
              isPaused={isPaused}
            />
          </FocusableComponent>
        </div>
        <LockBody />
      </Player>
    </FocusContext.Provider>
  );
}

export default PlayerContainer;
