import React, { useState, useEffect } from "react";

interface Props {
  text: string;
  delay: number;
  onScrollTick: () => void;
  onFinish: () => void;
}
const Typewriter: React.FC<Props> = (props) => {
  const [currentText, setCurrentText] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (currentIndex % 30 === 0) {
      props.onScrollTick();
    }
  }, [currentIndex]);

  useEffect(() => {
    startWrite();
  }, [props.text]);

  useEffect(() => {
    if (currentIndex < props.text.length) {
      const timeout = setTimeout(() => {
        setCurrentText((prevText) => prevText + props.text[currentIndex]);
        setCurrentIndex((prevIndex) => prevIndex + 1);
      }, props.delay);

      return () => clearTimeout(timeout);
    }
    props.onFinish();
  }, [currentIndex, props.delay]);

  const startWrite = () => {
    setCurrentText("");
    setCurrentIndex(0);
  };
  return <div>{currentText}</div>;
};

export default Typewriter;
