import React, { useState, useEffect } from "react";
import { Episode, Recommendation } from "../components";
import pepAxios from "../api/contents";
import { ShowOverview } from "../constants/types";
import { usePlayer } from "../context/PlayerContext";
import { GENERAL_SECTIONS } from "../api/contentsEndpoints";
import FocusableComponent from "./focusableComponent";
import TranslatedText from "../components/TranslatedText";
import { useSelector } from "react-redux";
import { ReducerGraphic, Reducers } from "../types/reducers";

type RecommendationContainerType = {
  catalog: number;
  categories: number[];
  openId: number;
};
const RecommendationContainer: React.FC<RecommendationContainerType> = (
  props
) => {
  const [recommendations, setRecommendations] = useState<ShowOverview>();
  const [showAll, setShowAll] = useState(false);
  const graphic: ReducerGraphic = useSelector(
    (store: Reducers) => store.graphic
  );
  const {
    detailsTrailer: { setDetailsTrailer },
    detailsContent: { detailsContent, setDetailsContent },
    trailerDisplayed: { trailerDisplayed, setTrailerDisplayed },
  } = usePlayer();

  useEffect(() => {
    const bodyParams = {
      filters: {
        // TODO: la chiamata prende le categorie come un and e restituisce solo i contenuti che le presentano entrambe
        // andrebbe trasformata in un or. Al momento risolvo passando solo la prima
        categories: props.categories ? [props.categories[0]] : [],
        tags: null,
        guests: null,
        query: "",
      },
    };
    if (!props.catalog || props.catalog < 0) return;
    pepAxios
      .post(
        GENERAL_SECTIONS.helpers.fetchCategoryContents.replace(
          "{{catalog_id}}",
          props.catalog.toString()
        ),
        bodyParams
      )
      .then((response) => {
        setRecommendations(
          response.data
            .filter((content: any) => content.id !== props.openId && content.localized[graphic.currentLanguage])
            .map((content: any) => {
              return {
                id: content.id,
                overview: content.localized[graphic.currentLanguage].description || "",
                poster_path: content.localized[graphic.currentLanguage].img_url,
                backdrop_path: content.localized[graphic.currentLanguage].img_url,
                genre_ids: [content.id_content_type],
                popularity: 0,
                vote_average: 0,
                vote_count: 0,
                original_language: content.locales,
                original_title: content.localized[graphic.currentLanguage].title,
                title: content.localized[graphic.currentLanguage].title,
                name: content.localized[graphic.currentLanguage].title,
                original_name: content.localized[graphic.currentLanguage].title,
                content_url: content.localized[graphic.currentLanguage].audio_url
                  ? content.localized[graphic.currentLanguage].audio_url
                  : content.localized[graphic.currentLanguage].video_url,
                content_audio_url: content.localized[graphic.currentLanguage].audio_url || "",
                content_video_url: content.localized[graphic.currentLanguage].video_url || "",
                content_categories: content.categories.map(
                  (cat: any) => cat.value
                ),
                release_date: content.created,
                catalog: props.catalog,
                tags: content.tags,
              };
            })
        );
      });
  }, [props.categories, props.openId, props.catalog, graphic.currentLanguage]);

  const truncate = (string: string, length: number) => {
    if (!string) return string;
    return string.length > length
      ? string.substr(0, length - 1) + "..."
      : string;
  };

  return recommendations && recommendations.length > 0 ? (
      <Recommendation.Container>
        <Recommendation.Header><TranslatedText translationKey="recommendations.similarContents" /></Recommendation.Header>
        {recommendations && (
          <Recommendation.Grid>
            {recommendations.map(
              (recommendation: any, i: number) =>
                (showAll || (!showAll && i < 9)) && (
                  <FocusableComponent
                    scrollPageIsActive
                    key={recommendation.id}
                    enterPressed={() => {
                      setDetailsTrailer({
                        id: recommendation.id.toString(),
                        key: recommendation.content_url,
                        start: 0,
                      });
                      setDetailsContent({
                        id: recommendation.id.toString(),
                        header: recommendation.title,
                        url: recommendation.content_url,
                        isLoaded: false,
                      });
                      setDetailsContent({
                        ...recommendation,
                      });
                    }}
                  >
                    <Recommendation
                      onClick={() => {
                        setDetailsTrailer({
                          id: recommendation.id.toString(),
                          key: recommendation.content_url,
                          start: 0,
                        });
                        setDetailsContent({
                          id: recommendation.id.toString(),
                          header: recommendation.title,
                          url: recommendation.content_url,
                          isLoaded: false,
                        });
                        setDetailsContent({
                          ...recommendation,
                        });
                      }}
                    >
                      <Recommendation.Banner
                        src={recommendation.backdrop_path}
                      />
                      <Recommendation.Details>
                        <Recommendation.Title>
                          {recommendation.name || recommendation.title}
                        </Recommendation.Title>
                        {/* <Recommendation.Year>
                          <TranslatedText translationKey="recommendations.createdOn" />{" "}
                          {new Date(
                            recommendation.release_date
                          ).toLocaleDateString()}
                        </Recommendation.Year> */}
                        <p>{truncate(recommendation.overview, 175)}</p>
                      </Recommendation.Details>
                    </Recommendation>
                  </FocusableComponent>
                )
            )}
          </Recommendation.Grid>
        )}
        {recommendations && recommendations.length > 9 && (
          <Recommendation.ShowMoreWrapper>
            <FocusableComponent
              enterPressed={() => setShowAll((showAll) => !showAll)}
            >
              <Episode.ShowMore
                onClick={() => setShowAll((showAll) => !showAll)}
                showAll={showAll}
              />
            </FocusableComponent>
          </Recommendation.ShowMoreWrapper>
        )}
      </Recommendation.Container>
  ) : null;
};

export default RecommendationContainer;
