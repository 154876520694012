import styled from 'styled-components/macro';

export const Inner = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    position: absolute;
    bottom: 15px;
    left: 0;
    max-width: 95%;
    margin: 0 auto;
    background: #191919;
    padding: 10px;
    overflow: hidden;
    border-radius: 8px;
    z-index: 2;

    .audioplayer-track-name {
        font-size: .8em;
        font-weight: bold;
        letter-spacing: .3px;
    }

    .audioplayer-controls-container {
        position: relative;
        width: 90%;
        margin: 10px auto;
        display: flex;
        flex-direction: row;
        gap: 10px;
        justify-content: space-between;
        align-items: center;
    }

    .audioplayer-control-button {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-wrap: nowrap;
        height: 24px;
        width: 24px;
        padding: 5px;
        border-radius: 50%;
        background: #ffffff;
    }

    .audioplayer-control-button__disabled {
        opacity: .7;
        cursor-pointer: not-allowed;
        pointer-events: none;
    }

    .audioplayer-progressbar {
        position: relative;
        width: 100%;
        height: 3px;
        background-color: rgba(255,255,255,.14);
        border-radius: 8px;
        margin-bottom: 10px;
        // overflow: hidden;
    }

    .audioplayer-progressbar > div {
        position: relative;
        height: 100%;
        background-color: #fff;
        transition: width 100ms linear;
    }

    .audioplayer-progressbar::-webkit-slider-thumb, .audioplayer-progressbar::-moz-range-thumb {
        background: #fff;
        color: #fff;
        cursor: pointer;
    }

    .audioplayer-progressbar::-webkit-slider-runnable-track, .audioplayer-progressbar::-moz-range-track {
        position: relative;
        height: 100%;
        background-color: #212121;
        transition: width 100ms linear;
    }      
`;

export const EmptyContainer = styled.div`
    position: absolute;
    bottom: 15px;
    left: 0;
    height: 116px;
    width: 160px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    background: #252525;
    padding: 10px;
    overflow: hidden;
    border-radius: 8px;

    .infinite-loader {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-wrap: nowrap;
        height: 24px;
        width: 24px;
        -webkit-animation: rotating 580ms ease-out infinite;
        -moz-animation: rotating 580ms ease-out infinite;
        -ms-animation: rotating 580ms ease-out infinite;
        -o-animation: rotating 580ms ease-out infinite;
        animation: rotating 580ms ease-out infinite;
    }

    @-webkit-keyframes rotating {
        from {
          -webkit-transform: rotate(0deg);
          -o-transform: rotate(0deg);
          transform: rotate(0deg);
        }
        to {
          -webkit-transform: rotate(360deg);
          -o-transform: rotate(360deg);
          transform: rotate(360deg);
        }
    }

    @keyframes rotating {
        from {
            -ms-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -webkit-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        to {
            -ms-transform: rotate(360deg);
            -moz-transform: rotate(360deg);
            -webkit-transform: rotate(360deg);
            -o-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
`;