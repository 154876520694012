import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import english from "./resources/languages/en.json";
import italian from "./resources/languages/it.json";

const translations = {
  en: {
    translation: english,
  },
  it: {
    translation: italian,
  },
};
const FALLBACK_LANGUAGE = "it";

const browserLanguage = navigator
  ? navigator.language.split("-")[0]
  : FALLBACK_LANGUAGE; // e.g. from navigator output 'it-IT' returns 'it'
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: translations,
    // TODO: remove force language to 'it'
    // lng: browserLanguage ? browserLanguage : FALLBACK_LANGUAGE,
    lng: FALLBACK_LANGUAGE,
    fallbackLng: FALLBACK_LANGUAGE,
    interpolation: {
      escapeValue: false,
    },
    saveMissing: true,
    parseMissingKeyHandler: (key) => {
      return key;
    },
    missingKeyHandler: () => null,
  });
