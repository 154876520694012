import {
  FocusContext,
  useFocusable,
} from "@noriginmedia/norigin-spatial-navigation";
import React, { useEffect } from "react";

type Props = {
  children?: any;
};

const DetailsButtonsContainer: React.FC<Props> = (props) => {
  const { ref, focusKey, focusSelf } = useFocusable();

  useEffect(() => {
    focusSelf();
  }, [focusSelf]);

  return (
    <FocusContext.Provider value={focusKey}>
      <div className="buttons-container" ref={ref}>
        {props.children}
      </div>
    </FocusContext.Provider>
  );
};

export default DetailsButtonsContainer;
