import { ComponentProp } from "../../constants/types";
import {
  Container,
  HeaderImage,
  HeaderText,
  Inner,
  Overlay,
  Subtitle,
  Title,
} from "./styles/newsletterBanner";

type RefProp = {
  childRef: React.RefObject<HTMLDivElement>;
  children: any;
  [x: string]: any;
};

function NewsletterBannerContainer({ children, ...restProps }: ComponentProp) {
  return <Container {...restProps}>{children}</Container>;
}

NewsletterBannerContainer.Inner = function DetailsInner({
  childRef,
  children,
  ...restProps
}: RefProp) {
  return (
    <Inner ref={childRef} {...restProps}>
      {children}
    </Inner>
  );
};

NewsletterBannerContainer.HeaderImage = function DetailsOverlay({
  ...restProps
}) {
  return <HeaderImage {...restProps} />;
};

NewsletterBannerContainer.HeaderText = function DetailsOverlay({
  children,
  ...restProps
}: ComponentProp) {
  return <HeaderText {...restProps}>{children}</HeaderText>;
};

NewsletterBannerContainer.Overlay = function DetailsOverlay({ ...restProps }) {
  return <Overlay {...restProps} />;
};

NewsletterBannerContainer.Title = function DetailsTitle({
  children,
  ...restProps
}: ComponentProp) {
  return <Title {...restProps}>{children}</Title>;
};

NewsletterBannerContainer.Subtitle = function DetailsTitle({
  children,
  ...restProps
}: ComponentProp) {
  return <Subtitle {...restProps}>{children}</Subtitle>;
};

export default NewsletterBannerContainer;
