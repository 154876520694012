import { applyMiddleware, combineReducers, createStore } from 'redux'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import thunk from 'redux-thunk'
import graphic from './graphic'

const config = {
    key: 'root',
    storage: storage,
    whitelist: ['graphic'],
    blacklist: []
}

// Group reducers
const rootReducer = combineReducers({
    graphic
})

// Middleware: Redux Persist Persisted Reducer
const persistedReducer = persistReducer(config, rootReducer)

const createStoreWithMiddleware = applyMiddleware(thunk)(createStore)
const store = createStoreWithMiddleware(persistedReducer)
const persistor = persistStore(store)

export { persistor, store }
