import styled from 'styled-components/macro';

export const Container = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	z-index: 100;
	background-color: #000;
	width: 100vw;
	height: 100vh;
	display: flex;
	.paused-text {
		position: absolute;
		bottom: 8rem;
		right: 8rem;
		@media (max-width: 600px) {
			bottom: 8rem;
			right: 3rem;
		}
	}
`;
export const Overlay = styled.div`
z-index: 2;
	background: linear-gradient(0.25turn, rgba(20, 20, 20, 0.75), rgba(20, 20, 20, 0));
	width: 100vw;
	height: 100vh;
	position: absolute;
`;
export const Details = styled.div`
z-index: 2;
	position: absolute;
	top: 0;
	color: #b3b3b3;
	font-size: 1.35rem;
	max-width: 50rem;
	margin: 18rem 8rem;

	&:after {
		position: absolute;
		content: '';
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
	}

	@media (max-width: 600px) {
		font-size: 1rem;
		margin: 8rem 2rem;
	}
`;
export const Title = styled.div`
	font-weight: 800;
	font-size: 3rem;
	margin: 1rem 0 2rem;
	color: #fff;
	@media (max-width: 600px) {
		font-size: 2rem;
		margin: 0.5rem 0 1rem;
	}
`;
export const ImagePlaceholder = styled.div`
	z-index: -1;
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;
	justify-content: center;
	align-items: center;

	img {
		position: relative;
		height: auto;
		width: auto;
		max-height: 100%;
		max-width: 100%;
	}
`;
export const Video = styled.div`width: 100vw;`;
export const Back = styled.div`
	border-radius: 4px;
	background: rgba(0,0,0,.64);
	
	.MuiSvgIcon-root {
		font-size: 3rem;
		width: 3rem;
		height: 3rem;
		margin: 1rem;
		cursor: pointer;
	}
`;
