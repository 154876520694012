import React from 'react';
import { ComponentProp } from '../../constants/types';
import { Container, Row, NoMatch } from './styles/search';

function Search({ children, ...restProps }: ComponentProp) {
	return <Container {...restProps}>{children}</Container>;
}

Search.Row = function SearchRow({ children, ...restProps }: ComponentProp) {
	return <Row {...restProps}>{children}</Row>;
};

Search.EmptySearch = function SearchEmptySearch({ ...restProps }) {
	return (
		<NoMatch {...restProps}>
			<p>Cerca un contenuto digitandone il titolo.</p>
		</NoMatch>
	);
}

Search.NoMatch = function SearchNoMatch({ ...restProps }) {
	return (
		<NoMatch {...restProps}>
			<p>La ricerca non ha prodotto risultati.</p>
		</NoMatch>
	);
};

export default Search;
