import { useState, useEffect } from "react";
import movieHttp from "../api/movie";
import { Show } from "../components";
import ShowContainer from "./show";
import { SectionType } from "../api/movieEndpoints";
import { ShowOverview } from "../constants/types";
import pepAxios from "../api/contents";
import { GENERAL_SECTIONS } from "../api/contentsEndpoints";
import {
  FocusContext,
  useFocusable,
} from "@noriginmedia/norigin-spatial-navigation";
import FocusableComponent from "./focusableComponent";
import { useSelector } from "react-redux";
import { ReducerGraphic, Reducers } from "../types/reducers";
import { updateContentUrl } from "../resources/utility/functions";

type SliderContainerType = {
  section: SectionType | null;
  category?: any;
  catalog?: number;
  [x: string]: any;
};

/** const ShowRowComponent = React.forwardRef((props: any, ref: any) => (
    {props.children}
  </Show.Row>
)); */

function SliderContainer({ section, category, catalog }: SliderContainerType) {
  const graphic: ReducerGraphic = useSelector(
    (store: Reducers) => store.graphic
  );
  const [shows, setShows] = useState<Array<ShowOverview>>();
  const [focusedElementBounds, setFocusedElementBounds] = useState([0, 0]); // [posX, width]
  const { ref, focusKey } = useFocusable();

  useEffect(() => {
    if (section) {
      movieHttp
        .get(section.endpoint)
        .then((response) => setShows(response.data.results))
        .catch((e) => console.log(e));
    } else if (category && catalog && catalog >= 0) {
      const bodyParams = {
        filters: {
          categories: [category.id],
          guests: null,
          query: "",
          tags: null,
        },
      };
      pepAxios
        .post(
          GENERAL_SECTIONS.helpers.fetchCategoryContents.replace(
            "{{catalog_id}}",
            catalog.toString()
          ),
          bodyParams
        )
        .then((response) =>
          setShows(
            response.data
              .filter(
                (content: any) =>
                  content.localized[graphic.currentLanguage] &&
                  (content.localized[graphic.currentLanguage].audio_url ||
                    content.localized[graphic.currentLanguage].video_url)
              )
              .map((content: any) => {
                return {
                  id: content.id,
                  overview:
                    content.localized[graphic.currentLanguage].description,
                  poster_path: updateContentUrl(
                    content.localized[graphic.currentLanguage].img_url
                  ),
                  backdrop_path: updateContentUrl(
                    content.localized[graphic.currentLanguage].img_url
                  ),
                  genre_ids: [content.id_content_type],
                  popularity: 0,
                  vote_average: 0,
                  vote_count: 0,
                  original_language: content.locales,
                  original_title:
                    content.localized[graphic.currentLanguage].title,
                  title: content.localized[graphic.currentLanguage].title,
                  name: content.localized[graphic.currentLanguage].title,
                  original_name:
                    content.localized[graphic.currentLanguage].title,
                  content_url: content.localized[graphic.currentLanguage]
                    .audio_url
                    ? updateContentUrl(
                        content.localized[graphic.currentLanguage].audio_url
                      )
                    : updateContentUrl(
                        content.localized[graphic.currentLanguage].video_url
                      ),
                  content_audio_url:
                    updateContentUrl(
                      content.localized[graphic.currentLanguage].audio_url
                    ) || "",
                  content_video_url:
                    updateContentUrl(
                      content.localized[graphic.currentLanguage].video_url
                    ) || "",
                  content_categories: content.categories.map(
                    (cat: any) => cat.value
                  ),
                  content_id_categories: content.categories.map(
                    (cat: any) => cat.id
                  ),
                  release_date: content.created,
                  tags: content.tags,
                  catalog,
                };
              })
          )
        );
    }
  }, [section, category, catalog, graphic.currentLanguage]);

  // useEffect(() => {
  //   console.log("focusedElementPosX", focusedElementPosX);
  //   console.log("rowRef", rowRef);
  //   if (rowRef && rowRef.current) {
  //     console.log("focusedElementPosX", focusedElementPosX);
  //     console.log("refPosX", rowRef.current);
  //     rowRef?.current.scrollTo({
  //       left: focusedElementPosX * -1,
  //       behavior: "smooth",
  //     });
  //   }
  // }, [focusedElementPosX, rowRef]);

  const saveChildrenInStorage = () => {
    const filteredShows = shows?.filter((show) => show.content_audio_url);
    sessionStorage.setItem("playingElementRow", JSON.stringify(filteredShows));
  };

  return shows && shows.length > 0 ? (
    <FocusContext.Provider value={focusKey}>
      <Show.Container>
        <div className="category-slider-title-container">
          <div>
            {category.value}
            <div
              style={{
                background: "#139513",
                height: 3,
                width: "40px",
                marginTop: 4,
              }}
            />
          </div>
        </div>
        <div
          style={{
            display: "inline-flex",
            flex: "0 0 auto",
            maxWidth: "100%",
            position: "relative",
            overflow: "hidden",
          }}
          ref={ref}
        >
          <Show.Row
            className="md-container"
            currentElementBounds={focusedElementBounds}
            numberOfChildren={shows.length}
          >
            {shows.map((show) => {
              return (
                show && (
                  <FocusableComponent
                    scrollPageIsActive
                    key={show.id}
                    focusChanged={(elementPosX, elementWidth) =>
                      setFocusedElementBounds([elementPosX, elementWidth])
                    }
                  >
                    <ShowContainer
                      saveAllChildrenOfRow={() => saveChildrenInStorage()}
                      show={show}
                      section={{ ...category, size: "md" }}
                    />
                  </FocusableComponent>
                )
              );
            })}
          </Show.Row>
        </div>
      </Show.Container>
    </FocusContext.Provider>
  ) : null;
}

export default SliderContainer;
