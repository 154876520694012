import styled from 'styled-components/macro';
import { IMAGE_BASE_URL, IMAGE_SIZES } from '../../../constants/constants';

interface OverlayProps {
	readonly fullOverlay?: boolean;
}
interface BannerProps {
	readonly src: string;
	readonly windowWidth: number;
}

export const Container = styled.div`
	color: #fff;
	z-index: 0;
`;
export const Overlay = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	z-index: 0;
	background: rgb(14,143,126);
	background: linear-gradient(120deg, rgba(14,143,126,1) 0%, rgba(4,83,72,1) 4%, rgba(0,0,0,.9) 25%, rgba(0,0,0,.4) 50%, rgba(0,0,0,0) 100%);
	// background: ${({ fullOverlay }: OverlayProps) =>
		(fullOverlay ? `linear-gradient(rgba(20, 20, 20, 0) 60%, rgba(20, 20, 20, 0.95)), ` : '') +
		'linear-gradient(0.25turn,rgba(20,20,20,1),rgba(20,20,20,.8),rgba(20,20,20,.5),rgba(20,20,20,0))'};
`;
export const Video = styled.div`
	width: 100%;
	height: 100vh;
	z-index: -99;

	.hero-video {
		pointer-events: none;
		z-index: 0;

		> div {
			position: relative;
			overflow: hidden;
			width: 100%;
			height: 115vh;
			z-index: 0;
			pointer-events: none;

			iframe {
				position: absolute;
				top: 50%;
				left: 50%;
				width: 100%;
				height: 115vh;
				pointer-events: none;
				z-index: 0;
				transform: translate(-50%, -50%);

				@media (min-aspect-ratio: 16/9) {
					height: 56.25vw;
				}

				@media (max-aspect-ratio: 16/9) {
					width: 177.78vh;
				}
			}
		}
	}

	@media (max-width: 1000px) {
		height: 24rem !important;
		> div {
			height: 65vh;
		}
	}
`;
export const Banner = styled.div`
	width: 100%;
	height: 100vh;
	background: ${({ src, windowWidth }: BannerProps) =>
		`linear-gradient(rgba(20,20,20,0) 60%, rgba(20,20,20,0.95)), url("${src}") center`};
	background-size: cover;
	z-index: 0;
	filter: brightness(0.7);
`;
export const Title = styled.h1`
	font-size: 4.5rem;
	letter-spacing: .6px;
	max-width: 60vw;
	line-height: 1.15;
	margin: 1rem 0 1rem;
	text-shadow: 0px 4px 3px rgba(20, 20, 20, 0.4), 0px 8px 13px rgba(20, 20, 20, 0.1),
		0px 18px 23px rgba(20, 20, 20, 0.1);
	transition: all 0.25s cubic-bezier(0.5, 0, 0.1, 1);

	&.title-small {
		font-size: 4.5rem;
		@media (max-width: 1000px) {
			max-width: 70vw;
			font-size: 3.2rem;
			margin-bottom: 0;
		}
	}

	@media (max-width: 1000px) {
		max-width: 25rem;
	}
`;
// bottom: 23rem;
export const Details = styled.div`
	position: absolute;
	left: 0;
	padding-left: 12.8rem;
	top: 15vh;
	z-index: 1;
	transition: all 0.25s cubic-bezier(0.5, 0, 0.1, 1);

	.hero-tags {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		gap: 13px;
		font-size: .8em;
		letter-spacing: .3px;

		label {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			position: relative;
		}

		label:not(:first-child):before {
			content: '';
			position: absolute;
			height: 3px;
			width: 3px;
			border-radius: 8px;
			background: #fff;
			opacity: .5;
			left: -8px;
		}
	}

	&.no-desc {
		padding-bottom: 15rem;
		@media (max-width: 1000px) {
			${Title} {
				font-size: 3.5rem;
				margin-bottom: 0.5rem;
			}
		}
	}

	@media (max-width: 600px) {
		padding-left: 12.8rem;
		padding-bottom: 2rem;
	}
`;
export const Description = styled.div`
	max-width: 50vw;
	letter-spacing: .3px;
	font-size: 1.4rem;
	line-height: 120%;
	margin-top: 16px;
	// margin-bottom: 32px;
	text-shadow: 2px 4px 3px rgba(20, 20, 20, 0.3);
	transition: all 0.25s cubic-bezier(0.5, 0, 0.1, 1);

	@media (max-width: 1000px) {
		max-width: 70vw;
		font-size: 1rem;
		line-height: 1.5;
	}
`;
export const Button = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 8px;
	background: rgba(0,0,0,.34);
	border: none;
	outline: none;
	padding: 0.2rem 2rem 0.2rem 1.5rem;
	font-size: 1.3rem;
	font-weight: bold;
	line-height: 100%;
	color: #fff;
	border-radius: 0.25rem;
	cursor: pointer;
	transition: all 0.2s;

	span {
		margin-top: 3px;
		letter-spacing: .3px;
	}

	&.white-btn {
		background: #fff;
		color: #141414;
		&:hover {
			background: #ffffffb8;
		}
	}

	&:focus,
	&:hover {
		background: #69696979;
	}

	.MuiSvgIcon-root {
		font-size: 2.5rem;
		vertical-align: middle;

		@media (max-width: 1000px) {
			font-size: 1.25rem;
			padding-right: 0.25rem;
		}
	}

	@media (max-width: 1000px) {
		font-size: 0.75rem;
		padding: 0.5rem 1rem;
	}
	@media (max-width: 600px) {
		margin-top: 0.5rem;
	}
`;
