import React from "react";
import { useTranslation } from "react-i18next";

interface TranslatedTextProps {
  translationKey: string;
}

export const TranslatedText: React.FC<TranslatedTextProps> = (props) => {
  const { t } = useTranslation();
  
  if (!t(props.translationKey)) return <></>;

  return <>{t(props.translationKey).toString()}</>;
};

export default TranslatedText;
