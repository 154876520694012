import styled from 'styled-components/macro';

export const Container = styled.div`
	padding: 2rem 3rem;

	@media (max-width: 600px) {
		padding: 1.5rem;
	}
`;
export const Grid = styled.div`
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-auto-rows: minmax(auto, auto);
	gap: 1rem;

	@media (max-width: 600px) {
		grid-template-columns: repeat(2, 1fr);
	}
`;
export const Card = styled.div`
	cursor: pointer;
	font-size: 0.9rem;
	line-height: 1.5;
	color: #d2d2d2;
	background-color: #2f2f2f;
	border-radius: 0.35rem;
	overflow: hidden;
	min-height: 100%;
	@media (max-width: 600px) {
		font-size: 0.8rem;
		line-height: 1.25;
	}
`;
export const BannerRecommendedContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	position: relative;
	height: 9rem;
	width: 100%;
	overflow: hidden;
	background-color: rgba(0,0,0,.6);
	border-radius: 0.35rem 0.35rem 0 0;
	img {
		position: relative;
	}

`;
export const Banner = styled.img`
	height: auto;
	max-width: 100%;
`;
export const Details = styled.div`
	padding: 0 1rem 1rem;
	@media (max-width: 600px) {
		padding: 0 0.5rem 0.5rem;
	}
	p {
		line-height: 140%;
		letter-spacing: .4px;
		font-size: .84em;
	}
`;
export const Header = styled.p`
	font-weight: 800;
	font-size: 1.5rem;
	margin-bottom: 1.5rem;
`;
export const Title = styled.p`
	font-size: 1em !important;
	font-weight: bold;
	letter-spacing: .3px;
	margin: 1rem 0;
	@media (max-width: 600px) {
		font-size: 0.8em !important;
		margin: 0.5rem 0;
	}
`;
export const Year = styled.p`
	font-size: 1rem;
	margin-bottom: 1rem;
	@media (max-width: 600px) {
		font-size: 0.8rem;
		margin-bottom: 0.5rem;
	}
`;
export const ShowMoreWrapper = styled.div`
	margin-top: 4rem;
	width: 100%;
	display: flex;
	justify-content: center;
`;
