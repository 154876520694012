import React, { useEffect, useMemo, useState } from "react";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import {
  Container,
  Overlay,
  Details,
  Title,
  Video,
  Back,
  ImagePlaceholder,
} from "./styles/player";
import { ComponentProp } from "../../constants/types";
import { PLAYER_CONFIG } from "../../constants/config";
import { PauseCircleOutline, PlayCircleOutline } from "@material-ui/icons";
import VideoJS from "../videojs";
import ReactPlayer from "react-player";
import axios from "axios";
import "video-react/dist/video-react.css"; // import css
import { Player as PlayerVideoReact } from "video-react";
import { convertSecondsToTimeString } from "../../resources/utility/functions";

function Player({ children, ...restProps }: ComponentProp) {
  return <Container {...restProps}>{children}</Container>;
}

Player.Back = function PlayerBack({ ...restProps }) {
  return (
    <Back {...restProps}>
      <ArrowBackIcon />
    </Back>
  );
};

Player.Pause = function PlayerPause(restProps: any) {
  return (
    <Back {...restProps}>
      {restProps.isPaused ? <PlayCircleOutline /> : <PauseCircleOutline />}
    </Back>
  );
};

Player.Overlay = function PlayerOverlay({ ...restProps }) {
  return <Overlay {...restProps} />;
};

Player.Details = function PlayerDetails({
  children,
  ...restProps
}: ComponentProp) {
  return <Details {...restProps}>{children}</Details>;
};

Player.Title = function PlayerTitle({ children, ...restProps }: ComponentProp) {
  return <Title {...restProps}>{children}</Title>;
};

Player.Placeholder = function PlayerPlaceholder({ image }: ComponentProp) {
  return (
    <ImagePlaceholder>
      <img src={image} />
    </ImagePlaceholder>
  );
};

Player.Video = function PlayerVideo({
  setIsPaused,
  isPaused,
  contentUrl,
  setError,
  handleMediaEnd,
  ...restProps
}: ComponentProp) {
  const {
    youtube: { playerVars },
  } = PLAYER_CONFIG;
  const config = { youtube: { playerVars: { ...playerVars, start: 0 } } };

  const prerollVideoUrl =
    "https://22hbgtest.s3.eu-west-2.amazonaws.com/naturhouse/Intro+NaturHouse.mp4";

  const [currentMediaPlaying, setCurrentMediaPlaying] = useState<string>("");
  const [prerollHasEnded, setPrerollHasEnded] = useState<boolean>(false);
  const [isFirstPlay, setIsFirstPlay] = useState<boolean>(true);
  const [error, setErrorString] = useState<string>("");
  const [timesValues, setTimesValues] = useState<number[]>([0, 0]);

  // const reactPlayerRef = React.useRef<ReactPlayer>(null);
  const playerVideoReactRef = React.useRef<any>(null);

  // const videoJsOptions = {
  //   normalizeAutoplay: true,
  //   notSupportedMessage: "The video is not supported. PEPERR075",
  //   autoplay: "play", // true,
  //   controls: true,
  //   responsive: true,
  //   fluid: true,
  //   // src: contentUrl,
  //   sources: [
  //     {
  //       src: contentUrl,
  //       type: "video/mp4",
  //     },
  //   ],
  // };

  const sendErrorString = async (error: any, data: any) => {
    setErrorString(JSON.stringify({ error, data }, undefined, 1));
    try {
      await axios.post(
        "https://solutions.api-services.cloud/telegram/api-telegram/message/-1001248017029",
        { message: error.message }
      );
      await axios.post(
        "https://solutions.api-services.cloud/telegram/api-telegram/message/-1001248017029",
        { message: data.toString() }
      );
    } catch (error) {
      console.error(error);
    }
  };

  // const handlePlayerReady = (player: any) => {
  //   playerRef.current = player;

  //   // You can handle player events here, for example:
  //   player.on("waiting", () => {
  //     console.log("player is waiting");
  //   });

  //   player.on("dispose", () => {
  //     console.log("player will dispose");
  //   });
  // };

  useEffect(() => {
    if (!playerVideoReactRef || !playerVideoReactRef.current) return;
    if (isPaused) {
      playerVideoReactRef.current.pause();
    } else {
      playerVideoReactRef.current.play();
    }
  }, [playerVideoReactRef, isPaused]);

  useEffect(() => {
    setCurrentMediaPlaying(prerollVideoUrl);
    setIsFirstPlay(true);
  }, [contentUrl]);

  // handles the player's properties update
  const handlePlayerStateChange = (state: any, prevState: any) => {
    // if the preroll video was ended
    if (!state.ended && state.currentSrc !== prerollVideoUrl) {
      setTimesValues([state.currentTime, state.duration]);
    }
    if (state.ended) {
      if (state.currentSrc === prerollVideoUrl) {
        setPrerollHasEnded(true);
      } else {
        setTimeout(() => {
          handleMediaEnd();
        }, 500);
      }
    }
  };

  // subscribe to player change
  useEffect(() => {
    if (!playerVideoReactRef.current) return;
    playerVideoReactRef.current.subscribeToStateChange(handlePlayerStateChange);
  }, [playerVideoReactRef]);

  useEffect(() => {
    if (prerollHasEnded && isFirstPlay) {
      setCurrentMediaPlaying(contentUrl);
      playerVideoReactRef.current.play();
      setTimeout(() => {
        setIsFirstPlay(false);
      }, 50);
    }
  }, [prerollHasEnded, isFirstPlay, contentUrl, playerVideoReactRef]);

  const videoPlayer = useMemo(() => {
    return (
      <PlayerVideoReact
        ref={playerVideoReactRef}
        autoPlay
        src={currentMediaPlaying}
      >
        <div />
        {/* <source src={currentMediaPlaying} /> */}
      </PlayerVideoReact>
    );
  }, [currentMediaPlaying]);

  return (
    <Video {...restProps}>
      {error !== "" ? (
        <h1 style={{ height: "100%", width: "100%", backgroundColor: "green" }}>
          {error}
        </h1>
      ) : null}
      {prerollHasEnded &&
        !Number.isNaN(timesValues[0]) &&
        !Number.isNaN(timesValues[1]) && (
          <div
            style={{
              position: "absolute",
              bottom: 0,
              width: "100vw",
              padding: "12px 24px",
              zIndex: 9999,
              backgroundColor: "rgb(0,0,0)",
              background:
                "linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 19%, rgba(255,255,255,0) 100%)",
            }}
          >
            <div
              style={{
                color: "#fff",
                fontFamily: "MullerBold",
                display: "flex",
                gap: 8,
              }}
            >
              <span style={{ width: 73 }}>
                {convertSecondsToTimeString(timesValues[0])}
              </span>
              <span>/</span>
              <span style={{ width: 73 }}>
                {convertSecondsToTimeString(timesValues[1])}
              </span>
            </div>
          </div>
        )}
      {videoPlayer}

      {/* <ReactPlayer
        ref={reactPlayerRef}
        url={
          contentUrl
        }
        className="playing-video"
        width="100%"
        height="100%"
        playing={!isPaused}
        // playing
        controls
        onReady={() => setIsPaused(false)}
        config={config}
        onError={(error, data) => sendErrorString(error, data)}
        onPause={() => setIsPaused(true)}
        onPlay={() => setIsPaused(false)}
      /> */}
      {/* <VideoJS options={videoJsOptions} onReady={handlePlayerReady} /> */}
      {/* <video  width="320" height="240" autoPlay>
        <source src={contentUrl} type="video/mp4" />
        Your browser does not support the video tag.
      </video> */}
    </Video>
  );
};

export default Player;
