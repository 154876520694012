import {
  faAnglesLeft,
  faAnglesRight,
  faChevronLeft,
  faChevronRight,
  faPause,
  faPlay,
  faSpinner,
  faVolumeMute,
  faVolumeUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  FocusContext,
  useFocusable,
} from "@noriginmedia/norigin-spatial-navigation";
import React, { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import { ShowOverview } from "../../constants/types";
import FocusableComponent from "../../containers/focusableComponent";
import { usePlayer } from "../../context/PlayerContext";
import { EmptyContainer, Inner } from "./styles/audioPlayer";

interface AudioPlayerProps {
  searchIsActive: boolean;
}

const AudioPlayer: React.FC<AudioPlayerProps> = (props) => {
  const [paused, setIsPaused] = useState(false);
  const [loading, setLoading] = useState(false);
  const [playedFraction, setPlayedFraction] = useState(0);
  const [contentDuration, setContentDuration] = useState(0);
  const [currentUrl, setCurrentUrl] = useState("");
  const [currentTrackPosition, setCurrentTrackPosition] = useState("");
  const [availablePlayingContents, setAvailablePlayingContents] = useState<
    ShowOverview[]
  >([]);
  const [playingContentIndex, setPlayingContentIndex] = useState(-1);
  const {
    category: { category },
    playing: { playing, setPlaying },
    isMuted: { isMuted, setIsMuted },
    detailsContent: { detailsContent },
  } = usePlayer();

  const playerRef = useRef<any>();

  const { ref, focusKey } = useFocusable({
    focusable: !!playing,
    isFocusBoundary: false,
  });

  // useEffect(() => {
  //   setIsMuted(false);
  // }, []);

  useEffect(() => {}, []);

  useEffect(() => {
    if (!playing) return;
    setLoading(true);
    setIsMuted(false);
    // controllo dell'id della traccia audio e cambio delle key
    // del sessionStorage se l'id è cambiato
    let trackId = sessionStorage.getItem("currentTrackId");
    let trackUrl = "";
    if (!trackId || (trackId && trackId !== playing.id.toString())) {
      sessionStorage.setItem("currentTrackId", playing.id.toString());
      sessionStorage.setItem("currentAudioUrl", playing.content_url);
      setCurrentUrl(playing.content_url);
      setIsPaused(false);
      // in caso contrario recupera l'url della traccia audio dal
      // sessionStorage
    } else {
      trackUrl = sessionStorage.getItem("currentAudioUrl") || "";
      setCurrentUrl(trackUrl);
    }
    setAvailablePlayingContents(
      JSON.parse(sessionStorage.getItem("playingElementRow") || "[]")
    );
    setLoading(false);
  }, [playing]);

  useEffect(() => {
    if (!availablePlayingContents && !(availablePlayingContents as any).length)
      return;
    setPlayingContentIndex(
      availablePlayingContents.findIndex(
        (content) => content.id === playing?.id
      )
    );
  }, [availablePlayingContents, playing]);

  const truncate = (string: string, length: number) => {
    if (!string) return string;
    return string.length > length
      ? string.substr(0, length - 1) + "..."
      : string;
  };

  const calculatedProgress = () => {
    return playedFraction * 100;
  };

  const changeTrackPosition = () => {
    if (!currentTrackPosition) return;
    playerRef.current.seekTo(parseFloat(currentTrackPosition) / 100);
  };

  const checkContentIsAvailable = (option: "prev" | "next") => {
    const newIndex =
      option === "prev" ? playingContentIndex - 1 : playingContentIndex + 1;
    const updatedPlayingContent = availablePlayingContents[newIndex];
    return updatedPlayingContent;
  };

  const changePlayingContent = (option: "prev" | "next") => {
    const newContent = checkContentIsAvailable(option);
    if (newContent) setPlaying(newContent);
  };

  if(!playing || !playing?.content_audio_url) return <></>

  return (
    <>
      <FocusContext.Provider value={focusKey}>
        <div ref={ref}>
          {playing && currentUrl ? (
            <Inner ref={ref}>
              <span className="audioplayer-track-name">
                {truncate(playing?.name || "", 40)}
              </span>
              <div className="audioplayer-controls-container">
                <FocusableComponent
                  enterPressed={() => {
                    changePlayingContent("prev");
                  }}
                >
                  <div
                    onClick={() => {
                      changePlayingContent("prev");
                    }}
                    className={`audioplayer-control-button ${
                      checkContentIsAvailable("prev")
                        ? ""
                        : "audioplayer-control-button__disabled"
                    }`}
                  >
                    <FontAwesomeIcon icon={faAnglesLeft} color="#191919" />
                  </div>
                </FocusableComponent>

                <FocusableComponent enterPressed={() => setIsPaused(!paused)}>
                  <div
                    onClick={() => setIsPaused(!paused)}
                    className="audioplayer-control-button"
                  >
                    <FontAwesomeIcon
                      icon={paused ? faPlay : faPause}
                      color="#191919"
                    />
                  </div>
                </FocusableComponent>
                <FocusableComponent
                  enterPressed={() => {
                    changePlayingContent("next");
                  }}
                >
                  <div
                    onClick={() => {
                      changePlayingContent("next");
                    }}
                    className={`audioplayer-control-button ${
                      checkContentIsAvailable("next")
                        ? ""
                        : "audioplayer-control-button__disabled"
                    }`}
                  >
                    <FontAwesomeIcon icon={faAnglesRight} color="#191919" />
                  </div>
                </FocusableComponent>
              </div>
              {/** <div className="audioplayer-progressbar">
                <div style={{ width: `${calculatedProgress()}%` }} />
                </div> */}
              <input
                className="audioplayer-progressbar"
                onChange={(e) => {
                  setCurrentTrackPosition(e.target.value);
                }}
                onMouseUp={() => {
                  changeTrackPosition();
                }}
                type="range"
                min="0"
                max="100"
                value={`${calculatedProgress()}`}
              />
              <div>
                <p
                  style={{
                    fontSize: ".8rem",
                    fontWeight: "bold",
                    opacity: ".84",
                    backgroundColor: "rgba(0,0,0,.5)",
                    padding: "0.4rem 0.5rem",
                    borderRadius: "1rem",
                  }}
                >
                  v{process.env.REACT_APP_VERSION}
                </p>
              </div>
              {<ReactPlayer
                ref={playerRef}
                url={`${currentUrl}`}
                width="100%"
                height="100%"
                playing={!paused}
                muted={isMuted}
                onPause={() => setIsPaused(true)}
                onPlay={() => setIsPaused(false)}
            //     onSeek={(playedTime) =>{
            //         console.log("On seek: ", playedTime)
            //     setPlayedFraction(playedTime)
            // }}
                onDuration={(duration) => {
                  setContentDuration(duration);
                }}
                onProgress={({ played}) => {
                  setPlayedFraction(played);
                }}
              />}
            </Inner>
          ) : loading ? (
            <EmptyContainer>
              <div className="infinite-loader">
                <FontAwesomeIcon icon={faSpinner} color="#fff" />
              </div>
            </EmptyContainer>
          ) : null}
        </div>
      </FocusContext.Provider>
    </>
  );
};

export default AudioPlayer;
